<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("groupFinalMarksCountReport.title") }}
          </template>
          <template v-slot:toolbar>
          
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                        @change="reloadGroups"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        v-model="filter.level"
                        :options="levels"
                        @change="reloadGroups"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Класс
                    </template>
                    <b-form-select
                        v-model="filter.studentGroupId"
                        :options="groups"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary">{{
                  $t("common.filter")
                }}
              </b-button>
            </b-form>
            <GroupFinalMarksCountReportTable ref="schoolGroupFinalMarksCountReport"></GroupFinalMarksCountReportTable>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import GroupFinalMarksCountReportTable from "./partials/GroupFinalMarksCountReportTable.vue";

export default {
  name: "FinalMarksCountReport",
  components: {
    KTPortlet,
    GroupFinalMarksCountReportTable
  },
  data() {
    return {
      groups:[],
      levels:DictionariesService.levelOptions(),
      filter:{
        level:0,
        studentGroupId:0,
        educationYear: DictionariesService.currentYear()
      },
      typeConfig:{},
      data:{},
      reportItems:[],
      educationYears: DictionariesService.educationYearOptions(),
    };
  },
  mounted() {
      
  },
  methods: {
    reloadGroups(){
      ApiService.querySecured("dictionaries/groupsForFilter",
          {params: {educationYear: this.filter.educationYear, level: this.filter.level}})
          .then(({data}) => {
            data.dictionary.unshift({ value: 0, text: '' });
            this.groups = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    reloadData(){
      this.$refs['schoolGroupFinalMarksCountReport'].redrawTable(this.filter.studentGroupId);
    }
  }
};
</script>
