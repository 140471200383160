<template>
  <div :redraw="redraw">
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th></th>
        <th>{{ $t("groupFinalMarksCountReport.marks") }}</th>
        <th v-for="subject in subjects" :key="subject.subjectId" class="rotate">
          {{subject.subjectName}}
        </th>
        <th>
          {{ $t("groupFinalMarksCountReport.teachersCouncilDecision") }}
        </th>
      </tr>
      </thead>
      <tbody v-for="(quarter, qIndex) in quarters" :key="qIndex">
      <tr>
        <td rowspan="5">{{ $t("summaryReportOfStudentsProgress.quarter." + quarter) }}</td>
        <td>
          5
        </td>
        <td v-for="subject in subjects" :key="subject.subjectId">
          {{showMark(subject.subjectId, quarter, 5)}}
        </td>        
        <td rowspan="5">
          
        </td>
      </tr>
      <tr v-for="(marksLabel, mIndex) in marksLabels" :key="mIndex">
        <td>
          {{ marksLabel }}
        </td>
        <td v-for="subject in subjects" :key="subject.subjectId">
          {{showMark(subject.subjectId, quarter, marksLabel)}}
        </td>
      </tr>
      </tbody>
    </table>

    <div>
      <b-modal id="groupFinalMarksCountReportTable-modal" ref="groupFinalMarksCountReportTable-modal">
        <template v-slot:modal-title>
          {{ $t("summaryReportOfStudentsProgress.teachersCouncilDecision") }}
        </template>
        <b-form>
          <div>
            {{form.studentName}}
          </div>
          <b-form-group>
            <template v-slot:label>
              {{ $t("summaryReportOfStudentsProgress.teachersCouncilDecision") }}
            </template>
            <b-form-input
                v-model="form.teachersCouncilDecision"
                :state="modelState.teachersCouncilDecision"
            ></b-form-input>
            <div class="invalid-feedback">{{ modelSaveErrors.teachersCouncilDecision }}</div>
          </b-form-group>
        </b-form>

        <template v-slot:modal-footer>
          <b-button type="submit" @click="onSubmit" variant="primary" id="save-subject-data-button">
            {{ $t("common.save") }}
          </b-button>
          <b-button type="reset" @click="onReset" variant="danger">
            {{ $t("common.cancel") }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>  
</template>
<style>
.dropped td {
  background-color: #F64E60;
}

th.rotate {
  writing-mode: vertical-rl;
}

</style>
<script>
import ApiService from "@/common/api.service";

export default {
  name: "GroupFinalMarksCountReportTable",
  components: {
  },
  data() {
    return {
      students:[],
      subjects:[],
      marks:{},
      quarters:[1, 2, 3, 4, 5, 6, 7],
      marksLabels:[4, 3, 2, 1],
      redraw:0,
      form:{
        teachersCouncilDecision: "",
        studentName: "",
        studentId: 0,
      },
      modelState:{},
      modelSaveErrors:{},
      modalName: "groupFinalMarksCountReportTable-modal",
      groupId: -1
    };
  },
  mounted() {
      
  },
  methods: {
    redrawTable(groupId){
      this.groupId = groupId;
      let $this = this;
      
      ApiService.querySecured("schoolReports/groupFinalMarksCountReport", {
        params: { groupId: groupId }
      }).then(({data}) => {
        
        $this.subjects = data.subjects;

        for (var sIndex in $this.subjects){
          let subject = $this.subjects[sIndex];

          $this.marks[subject.subjectId] = {
            1:{},
            2:{},
            3:{},
            4:{},
            5:{},
            6:{},
            7:{}
          }
        }
        
        for (let i in data.marks){
          let mark = data.marks[i]
          
          if(typeof $this.marks[mark.subjectId] !== "undefined")
          {
            $this.marks[mark.subjectId][mark.quarter][mark.mark] = mark.count;
          }
        }
        
        $this.redraw = Math.random();
      }).catch((e) => {
        alert(e);
      });
    },
    showMark(subjectId, quarterIndex, markIndex){
      if(typeof this.marks[subjectId] !== "undefined"
        && typeof this.marks[subjectId][quarterIndex] !== "undefined"
        && typeof this.marks[subjectId][quarterIndex][markIndex] !== "undefined"  
      ){
        return this.marks[subjectId][quarterIndex][markIndex];
      }
      
      return '';
    },
    onSubmit(evt) {
      let submitButton = document.getElementById("save-subject-data-button");
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");

      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("journal/additional/saveTeachersCouncilDecision", this.form)
          .then(function () {
            $this.$refs[$this.modalName].hide();
            $this.redrawTable($this.groupId);
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveErrors = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;
              }
            }
          }).finally(function () {
        submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
      });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs[this.modalName].hide();
    },
    cleanModel() {
      this.form = {};
    },
    edit(item) {
      this.form = {
        studentId:item.studentToGroupId,
        studentName:item.studentName,
        teachersCouncilDecision:item.teachersCouncilDecision,        
      };
      this.$refs[this.modalName].show();
    }
  }
};
</script>
